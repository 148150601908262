.arrow {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.99) 61.11%,
    rgba(255, 255, 255, 0) 96.97%
  );
  position: absolute;
  color: #f1f1f1;
  font-size: 2.5em;
  font-weight: 700;
  cursor: pointer;
  background-color: rgba(226, 226, 227, 0.2);
  height: 128px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  text-align: center;
}
.MuiPaper-save {
  background-color: #ffffff;
  padding: 15% 20% !important;
  border-radius: 10px !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: none !important;
}
.slickcenter {
  background-color: #ffffff;
  padding: 15% 20% !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 32px 0px #0000001a !important;
  border: 1px solid #f2f2f2 !important;
  transform: scaleY(1.1) !important;
}

.MuiAccordion-root {
  color: #292929!important;
  box-shadow: none !important;
  border-bottom: 0.5px solid #292929 !important;
  background: #1d1d1b;
  margin: 0.3% 0%;
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(136deg) !important;
}
.marquee-container {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 60% !important;
  height: 33px !important;
}
.overlay {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  --gradient-color: none !important;
  z-index: 1111 !important;
}

.marquee {
  display: flex;
  justify-content: space-around !important;
}
/*ipad*/
@media (min-width: 768px) and (max-width: 1024px) {
  .marquee-container {
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 100% !important;
    height: 33px !important;
  }
  .MuiTabs-flexContainer {
    margin: 0% !important;
  }
}

@media (min-width: 1025px) and (max-width: 1304px) {
  .marquee-container {
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 90% !important;
    height: 33px !important;
  }
}
@media (min-width: 1305px) and (max-width: 1554px) {
  .marquee-container {
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 80% !important;
    height: 33px !important;
  }
  .MuiTabs-flexContainer {
    margin: 0% !important;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .marquee-container {
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 100% !important;
    height: 33px !important;
  }
  .MuiTableHead-root > tr > .MuiTableCell-root {
    font-size: 10px !important;
    padding: 1px 1px !important;
  }
  .MuiTableBody-root > tr > .MuiTableCell-root {
    font-size: 8px !important;
    padding: 5px 1px !important;
  }
  .orderTable {
    height: "45vh";
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  ul li {
    padding: 1%;
    line-height: 1.5rem !important;
}
  #videoplayer > div > .MuiDialog-paper {
    background-color: #fafaff00 !important;
    width: 100% !important;
    min-width: 100% !important;
    border-radius: 0px;
    box-shadow: none !important;
  }
  .marquee-container {
    overflow: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 70% !important;
    height: 33px !important;
  }
  .MuiDialog-paper {
    padding: 6% 4% !important;
  }
  .MuiTableHead-root > tr > .MuiTableCell-root {
    font-size: 10px !important;
    padding: 1px 1px !important;
  }
  .MuiTableBody-root > tr > .MuiTableCell-root {
    font-size: 8px !important;
    padding: 5px 1px !important;
  }
  .orderTable {
    height: "45vh";
  }
}

.Mui-checked + span {
  opacity: 1 !important;
}
.MuiRadio-root + span {
  opacity: 0.5;
}
.MuiFormControlLabel-root {
  margin-bottom: 6px;
}
.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  box-shadow: 0px 3px 12px 0px #4a3aff2e !important;
  border-radius: 12px !important;
  color: #373535 !important;
  font-size: 12px !important;
  font-family: "Almarai-Medium" !important;
}

.MuiAccordion-root:before {
  background-color: #c8bcff !important;
  position: relative !important;
}

.MuiAccordionDetails-root {
  padding: 1% 3% !important;
}
.MuiListItemText-root > span {
  font-size: 20px;
  font-family: "Almarai-SemiBold";
  text-align: center;
}
.submenu > span {
  font-size: 14px;
  font-family: "Almarai-Medium";
}
.MuiDrawer-paper {
  background-color: #C01718 !important;
  font-size: 20px;
  line-height: 22px;
  color: #E5E5E5!important;
  font-family: 'Almarai-SemiBold';
}
.MuiDialog-paper {
  background-color: #ffffff !important;
  text-align: center;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
}
.MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #373535 !important;
}
.MuiTab-root.Mui-selected {
  color: #ffffff !important;
  font-family: "Almarai-SemiBold";
}

/* .MuiTab-root.Mui-selected {
  color: #352e5b !important;
} */
/* .MuiTab-root {
  font-family: "Almarai-Medium" !important;
  font-size: 14px !important;
  justify-content: flex-start !important;
  text-align: left !important;
  color: #a5a2b8 !important;
  text-transform: capitalize !important;
  min-height: 62px !important;
} */
.MuiTabs-flexContainer {
  margin: 0% 2%;
}
.MuiTabs-root {
  border-right: none !important;
}

.MuiTabs-indicator {
  background-color: #267a33 !important;
  width: 20px !important;
  margin: 6px 24px !important;
}

#dashbord > div.MuiDialog-container > .MuiDialog-paper {
  border-radius: 10px !important;
  height: 90% !important;
}
#pay > div.MuiDialog-container > .MuiDialog-paper {
  width: 500px !important;
  padding: 2% !important;
}
.MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiSelect-icon {
  display: none !important;
}
.MuiTableHead-root > tr > .MuiTableCell-root {
  font-family: "Almarai-Bold" !important;
  color: white !important;
  font-size: 14px;
  padding: 16px 0px;
}
.MuiTableBody-root > tr > .MuiTableCell-root {
  font-family: "Almarai-Medium" !important;
  color: white !important;
  font-size: 14px;
  border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
  white-space: nowrap !important;
  padding: 12px 0px;
}
.MuiTablePagination-root {
  color: #fffafa !important;
}
#Dashboardmenu {
  min-height: 38% !important;
  height: 39% !important;
  overflow-y: auto !important;
}
#videoplayer > div > .MuiDialog-paper {
  background-color: #fafaff00 !important;
  width: 70%;
  min-width: 70%;
  border-radius: 0px;
}

ul li {
padding: 1%;line-height: 2rem;
}
.slider-wrapper{
  z-index: 0;
}